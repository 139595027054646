import { memoize, find, isMatch } from "lodash";
import { AssetConfig, KnownAsset, KnownAssetPredicate } from "@/types";
import baseAssetConfig from "@/config/base-asset";

interface GetAssetConfigResult {
  knownAsset?: KnownAsset;
  config: AssetConfig;
}

export const KNOWN_ASSETS: KnownAsset[] = [
  {
    name: "UBX-IAQ100",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "105b7109-c0d0-4f84-8b97-49b9dd342ca8",
    config: require("@/iaq/config/ubx-iaq-100")
  },
  {
    name: "UBX-IAQ101",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "7e3b1239-ad4f-465e-b893-5eefcc36c9c2",
    config: require("@/iaq/config/ubx-iaq-101")
  },
  {
    name: "UBX-IAQ102",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "ba943727-bd97-4741-be65-0c9880ed3fba",
    config: require("@/iaq/config/ubx-iaq-102")
  },
  {
    name: "UBX-IAQ200",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Indoor",
    knownAssetUuid: "5e29195f-50c7-4763-816b-2b54e679e998",
    config: require("@/iaq/config/ubx-iaq-200")
  },
  {
    name: "UBX-TEMP-RH100",
    category: "IT",
    type: "Sensor - IAQ",
    subtype: "Cold Storage",
    knownAssetUuid: "610dc07b-bdb2-4a3d-97f4-b4fba488a1bd",
    config: require("@/iaq/config/ubx-temp-rh-100")
  },
  {
    name: "UBX-NRG100",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "fa4338cc-3efc-400d-b704-e1b75a00ac44",
    config: require("@/energy-pro/config/ubx-nrg-100")
  },
  {
    name: "UBX-NRG200",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "3cfb8952-5f38-4dfa-95f7-d6dca255b392",
    config: require("@/energy-pro/config/ubx-nrg-200")
  },
  {
    name: "UBX-NRG201",
    category: "IT",
    type: "Sensor - Energy",
    knownAssetUuid: "af2ce77f-f29b-401d-bd89-c70a6f3bf3c4",
    config: require("@/energy-pro/config/ubx-nrg-201")
  },
  {
    name: "VS250CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "d105661b-2c48-42c1-9478-3fda89d7bc6d",
    config: require("@/vs/config/vs-250-cm")
  },
  {
    name: "VS250CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "1da06ab5-af2a-48a4-862f-9d8c7f65836b",
    config: require("@/vs/config/vs-250-cm")
  },
  {
    name: "VS400CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "b3d4541f-1486-4e56-8125-7dbe00e6ea64",
    config: require("@/vs/config/vs-400-cm")
  },
  {
    name: "VS400CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "e61cfbb9-abb9-4ad5-8499-dd661400e823",
    config: require("@/vs/config/vs-400-cm")
  },
  {
    name: "VS900CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "92f2fb13-0fca-4c4b-9407-7e42f6ea5f1c",
    config: require("@/vs/config/vs-900-cm")
  },
  {
    name: "VS900CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "da78c64b-793e-41c4-80f4-2419bb6aef2d",
    config: require("@/vs/config/vs-900-cm")
  },
  {
    name: "VS1200CMe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "f03dc154-31b3-43f3-85d8-177aa3cd9c8c",
    config: require("@/vs/config/vs-1200-cm")
  },
  {
    name: "VS1200CMh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "b4801aad-c6f6-43bf-a2c2-e2ae5f547218",
    config: require("@/vs/config/vs-1200-cm")
  },
  {
    name: "VS500SQe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "bf977338-f007-4580-9813-38bd0ffb3418",
    config: require("@/vs/config/vs-500-sq")
  },
  {
    name: "VS500SQh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "48433142-4d04-43c7-a188-1d376ec06be5",
    config: require("@/vs/config/vs-500-sq")
  },
  {
    name: "VS1000RTe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "3228bcfa-bf18-49fb-855f-5c68304972bc",
    config: require("@/vs/config/vs-1000-rt")
  },
  {
    name: "VS1000RTh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "392e4d4d-d3be-40b0-bd14-29c0ad23d184",
    config: require("@/vs/config/vs-1000-rt")
  },
  {
    name: "VS3000RTe",
    category: "HVAC",
    type: "ERV",
    knownAssetUuid: "973a0319-edf3-4576-aa88-9efb73536424",
    config: require("@/vs/config/vs-3000-rt")
  },
  {
    name: "VS3000RTh",
    category: "HVAC",
    type: "HRV",
    knownAssetUuid: "ff93b028-4d58-4bfb-a9e7-f3297b52ee61",
    config: require("@/vs/config/vs-3000-rt")
  },
  {
    name: "AOU48RLAVM",
    category: "HVAC",
    type: "VRF - ODU",
    knownAssetUuid: "a21c34f3-2355-4145-a35c-302f6d542adf",
    config: require("@/vrf/config/vrf-odu")
  },
  {
    name: "UTG-UFYC-W",
    category: "HVAC",
    type: "VRF - IDU",
    knownAssetUuid: "322cf051-1a6f-43e6-b887-a75babf1c25c",
    config: require("@/vrf/config/vrf-idu")
  },
  {
    name: "UBX-TSTAT100",
    category: "HVAC",
    type: "Thermostat",
    knownAssetUuid: "3c67bdfe-1a0a-44e8-8c5c-82885deb682a",
    config: require("@/tstat/config/ubx-tstat-100")
  },

  {
    name: "UBX-OCC100",
    category: "IT",
    type: "OCC",
    knownAssetUuid: "4f924526-45a4-4cf3-b2e2-7a482d2c014d",
    config: require("@/occupancy/config/ubx-occ-100")
  }
];

function matchKnownAsset(knownAsset: KnownAsset, predicate: KnownAssetPredicate): boolean {
  if (typeof predicate === "function") {
    return predicate(knownAsset);
  } else {
    return isMatch(knownAsset, predicate);
  }
}

export const getKnownAsset = memoize((predicate: KnownAssetPredicate): KnownAsset | undefined => {
  return find(KNOWN_ASSETS, ka => matchKnownAsset(ka, predicate));
});

export const getAssetConfig = memoize((knownAssetUuid?: string): GetAssetConfigResult => {
  if (!knownAssetUuid) return { config: baseAssetConfig };

  const knownAsset = getKnownAsset({ knownAssetUuid });
  if (knownAsset === undefined) {
    // eslint-disable-next-line no-console
    console.error(`Unable to find asset config for known asset UUID: ${knownAssetUuid}`);
    return { config: baseAssetConfig };
  }
  return { knownAsset, config: knownAsset.config.default };
});

export function findKnownAssets(predicate: KnownAssetPredicate): KnownAsset[] {
  return KNOWN_ASSETS.filter(ka => matchKnownAsset(ka, predicate));
}

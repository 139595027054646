import { unref, watchEffect, getCurrentInstance } from "vue";
import { MaybeRef } from "@vueuse/core";
import { onBeforeRouteLeave } from "vue-router/composables";
import store from "@/store";

export function useContextualTimeZone(timeZone: MaybeRef<string | null | undefined>): void {
  watchEffect(() => {
    if (unref(timeZone)) {
      store.commit("setContextualTimeZone", unref(timeZone) ?? null);
    }
  });

  if (getCurrentInstance()) {
    onBeforeRouteLeave((to, from, next) => {
      store.commit("setContextualTimeZone", null);
      next();
    });
  }
}

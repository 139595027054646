import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlCategory, MaybeRef } from "@/types";

export interface UseCategoriesOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseCategoriesResult {
  categories: Ref<GqlCategory[] | null>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useCategories({ enabled = true }: UseCategoriesOptions = {}): UseCategoriesResult {
  const categories = ref<GqlCategory[] | null>(null);
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query AssetCategoriesQuery {
        assetCategories {
          assetCategoryUuid
          name
          assetTypes {
            assetTypeUuid
            name
          }
        }
      }
    `,
    {},
    () => ({
      enabled: unref(enabled)
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      categories.value = queryResult.data.assetCategories;
    }
  });

  return { categories, loadingError, refetch, loading };
}

import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseDeviceConfig from "@/config/base-device";

const config = mergeAssetConfig(baseDeviceConfig, {
  i18nNamespace: "vrf",
  image: require("@/vrf/images/outdoor-unit.svg"),
  components: {
    AssetDashboardTab: () => import("@/vrf/components/DeviceDashboardTab.vue"),
    AssetControlPanel: () => import("@/vrf/components/OutdoorUnit.vue"),
    AssetConfigTab: () => import("@/vrf/components/OutdoorUnitConfigTab.vue")
  },
  properties: {
    set_all_iu_mode: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: ["AUTO", "COOL", "DRY", "HEAT", "FAN"],
        rules: { required: true }
      }
    },
    ou_low_noise_status: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        readonly: true,
        options: ["OFF", "LEVEL1", "LEVEL2", "LEVEL3"],
        rules: { required: true }
      }
    },
    ou_cap_save_status: {
      dataType: "string",
      category: "settings",
      fieldConfig: {
        displayType: "select",
        options: ["OFF", "100%", "90%", "80%", "70%", "60%", "50%", "40%"],
        rules: { required: true }
      }
    }
  }
});

export default config;

import { computed, reactive, unref, watchEffect } from "vue";
import { MaybeRef, useTitle } from "@vueuse/core";
import { DecoratedAsset, GqlBuilding, NavigationContext } from "@/types";
import { useContextualTimeZone } from "@/composables/use-contextual-time-zone";
import store from "@/store";

export interface NavigationContextRefs {
  building?: MaybeRef<GqlBuilding | null | undefined>;
  asset?: MaybeRef<DecoratedAsset | null | undefined>;
}

const TITLE_PREFIX = "UBX";

export function useNavigationContext(context: NavigationContextRefs = {}): void {
  resolveContext(context, resolvedContext => {
    store.commit("setNavContext", resolvedContext);
    setTitle(resolvedContext);
    setBreadcrumbs(resolvedContext);
  });

  useContextualTimeZone(computed(() => unref(context.building)?.timeZone));
}

function resolveContext(context: NavigationContextRefs = {}, cb: (context: NavigationContext) => void): void {
  const reactiveContext: NavigationContext = reactive(context);

  const unwatch = watchEffect(() => {
    const buildingMissing = "building" in reactiveContext && !reactiveContext.building;
    const assetMissing = "asset" in reactiveContext && !reactiveContext.asset;
    if (!buildingMissing && !assetMissing) {
      cb(reactiveContext);
      if (unwatch) unwatch();
    }
  });
}

function setTitle(context: NavigationContext): void {
  let title: string | null = null;
  if (context.asset) {
    title = context.asset.name;
  } else if (context.building) {
    title = context.building.name;
  }

  const fullTitle = title ? `${TITLE_PREFIX} | ${title}` : TITLE_PREFIX;
  useTitle(fullTitle);
}

function setBreadcrumbs(context: NavigationContext): void {
  const breadcrumbs: any[] = [];

  if (context.building) {
    breadcrumbs.push({
      text: context.building.name,
      to: { name: "building", params: { buildingUuid: context.building.buildingUuid } }
    });
  }

  if (context.asset) {
    breadcrumbs.push({
      text: context.asset.name,
      to: { name: "asset", params: { assetUuid: context.asset.assetUuid } }
    });
  }

  store.commit("setBreadcrumbs", breadcrumbs);
}

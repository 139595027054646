import { mergeAssetConfig } from "@/config/asset-config-reader";
import baseVentilatorConfig from "./base-ventilator";
import diagram from "@/vs/components/diagrams/CmVentilator.vue";

const AIRFLOW_RULES = {
  fieldConfig: {
    rules: {
      between: { min: 200, max: 900 }
    }
  }
};

const config = mergeAssetConfig(baseVentilatorConfig, {
  diagramComponent: diagram,
  properties: {
    target_airflow: AIRFLOW_RULES,
    zones_min_flow: AIRFLOW_RULES,
    zones_max_flow: AIRFLOW_RULES,
    schedule_airflow: AIRFLOW_RULES
  }
});

export default config;

import { render, staticRenderFns } from "./DeviceDiagram.vue?vue&type=template&id=968b740c&"
import script from "./DeviceDiagram.vue?vue&type=script&setup=true&lang=ts&"
export * from "./DeviceDiagram.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./DeviceDiagram.vue?vue&type=style&index=0&id=968b740c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTooltip})

import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlManufacturer, MaybeRef } from "@/types";

export interface UseManufacturersOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseManufacturersResult {
  manufacturers: Ref<GqlManufacturer[] | null>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useManufacturers(
  accountUuid: MaybeRef<string | null>,
  { enabled = true }: UseManufacturersOptions = {}
): UseManufacturersResult {
  const manufacturers = ref<GqlManufacturer[] | null>(null);
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query ManufacturersQuery($accountUuid: ID!) {
        manufacturers: assetAccountManufacturers(accountUuid: $accountUuid) {
          manufacturerUuid
          name
        }
      }
    `,
    {
      accountUuid
    },
    () => ({
      enabled: unref(enabled),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      manufacturers.value = queryResult.data.manufacturers;
    }
  });

  return { manufacturers, loadingError, refetch, loading };
}

import { ref, Ref, unref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { GqlModel, MaybeRef } from "@/types";

export interface UseModelsOptions {
  enabled?: MaybeRef<boolean>;
}

export interface UseModelsResult {
  models: Ref<GqlModel[] | null>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useModels(
  accountUuid: MaybeRef<string | null>,
  manufacturerUuid: MaybeRef<string | null>,
  { enabled = true }: UseModelsOptions = {}
): UseModelsResult {
  const models = ref<GqlModel[] | null>(null);
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query ModelsQuery($accountUuid: ID!, $manufacturerUuid: ID!) {
        models: assetAccountModels(accountUuid: $accountUuid, manufacturerUuid: $manufacturerUuid) {
          assetModelUuid
          name
        }
      }
    `,
    {
      accountUuid,
      manufacturerUuid
    },
    () => ({
      enabled: unref(enabled),
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    })
  );

  onError(() => {
    loadingError.value = true;
  });

  onResult(queryResult => {
    if (queryResult.data) {
      models.value = queryResult.data.models;
    }
  });

  return { models, loadingError, refetch, loading };
}

import { ref, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { UserProfile } from "@/types";

export interface UseUserProfileResult {
  userProfile: Ref<UserProfile | undefined>;
  loadingError: Ref<boolean>;
  refetch: () => void;
  loading: Ref<boolean>;
}

export function useUserProfile(): UseUserProfileResult {
  const userProfile: Ref<UserProfile | undefined> = ref();
  const loadingError = ref(false);

  const { onResult, onError, refetch, loading } = useQuery(
    gql`
      query UserProfileQuery {
        userProfile: userProfile {
          user {
            userUuid
            emailAddress
            activated
            pushButton
          }
          contactInfo {
            accountUuid
            contactInfoUuid
            emailAddress
            emailEnable
            name
            ownerUuid
            phoneNumber
            smsEnable
            isUserContactInfo
          }
        }
      }
    `
  );

  onError(() => {
    loadingError.value = true;
  });
  onResult(queryResult => {
    if (queryResult.data) userProfile.value = queryResult.data.userProfile;
  });

  return { userProfile, loadingError, refetch, loading };
}
